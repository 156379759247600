import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "./FormDialog.css"
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import axios from 'axios';
import {
    useMaterialUIController,
} from "context";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader"
export default function FormDialog({ open, handleClose, Values, edit }) {
    // const name = Values && Values ? Values.name : ""
    const [loader, setLoader] = useState(false);
    const [planName, setplanName] = useState("");
    const [planStatus, setplanStatus] = useState("active");
    const [controller, dispatch] = useMaterialUIController();
    const { userAuth } = controller;
    const token = userAuth.login_token;

    const handlePlanName = (e) => {

        setplanName(e.target.value)

    }
    const handleplanStatus = (e) => {
        setplanStatus(e.target.value)

    }
    const handlePlanCategory = () => {
        if (edit === true) {
            setLoader(true);
            var data = JSON.stringify({
                id: Values.id,
                plan_category_name: planName,
                status: planStatus
            });
            var config = {
                method: 'post',
                url: 'https://mizdah.mizdah.com/api/plans/create_update_plan_category',
                headers: {
                    'token': token,
                    'Accept': 'application/json',
                    'Authorization': 'Bearer asd',
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data.meta.code));
                    if (response.data.meta.code === "200") {
                        setLoader(false);
                        handleClose()
                        toast.success(<h6>Plan Category Added successfully</h6>);
                    }
                    // 
                })
                .catch(function (error) {
                    console.log(error);
                    setLoader(false);
                    handleClose()
                    toast.success(<h6>Something wrong happen</h6>);
                });
        }

        else {
            if (planName !== "") {
                setLoader(true);
                var data = JSON.stringify({
                    "plan_category_name": planName,
                    "status": planStatus
                });
                var config = {
                    method: 'post',
                    url: 'https://mizdah.mizdah.com/api/plans/create_update_plan_category',
                    headers: {
                        'token': token,
                        'Accept': 'application/json',
                        'Authorization': 'Bearer asd',
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        console.log(JSON.stringify(response.data.meta.code));
                        if (response.data.meta.code === "200") {
                            setLoader(false);
                            handleClose()
                            toast.success(<h6>Plan Category Added successfully</h6>);
                        }
                        // 
                    })
                    .catch(function (error) {
                        console.log(error);
                        setLoader(false);
                        handleClose()
                        toast.success(<h6>Something wrong happen</h6>);
                    });

            }
            else {
                handleClose()
                toast.error(<h6>Plan Name is required</h6>);
            }
        }
    }

    return (
        <div>
            {!loader ? (<Dialog open={open} onClose={handleClose} >
                <DialogTitle className='box-main-wrapper' display="flex" justifyContent="center">Plan Category</DialogTitle>
                <DialogContent style={{ overflowY: "hidden" }}>
                    <div>
                        <TextField
                            required
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Plan Category"
                            type="text"
                            defaultValue={Values && Values.name ? Values.name : planName}
                            fullWidth
                            variant="standard"
                            className='Meeting_heading'
                            onChange={handlePlanName}

                        // value={planName}
                        />
                    </div>

                    <div><FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native" className='Meeting_heading'>
                            Select Status
                        </InputLabel>
                        <NativeSelect
                            defaultValue={Values && Values.status ? Values.status : "Active"}
                            inputProps={{
                                name: 'age',
                                id: 'uncontrolled-native',

                            }}
                            className='Meeting_heading'
                            onChange={handleplanStatus}

                        >
                            {/* <option value={10}  ></option> */}
                            <option value={"active"}>Active</option>
                            <option value={"disable"}>Disable</option>
                        </NativeSelect>
                    </FormControl></div>
                    <div className='basic-plan-label'>
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                        <label for="vehicle1"> Basic Plan</label></div>
                    <div className='plan-category-btn'><button onClick={handlePlanCategory}>Submit</button></div>

                </DialogContent>

            </Dialog>
            ) : <FullPageLoader />
            }
        </div >
    );
}
