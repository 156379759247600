import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useLocation } from "react-router-dom";
function Overview() {
  const location = useLocation();
  const data = location?.state;
  console.log(data, "datadata");
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header data={data}>
        <MDBox mt={5} mb={0}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                info={{
                  fullName: `${
                    data?.user?.first_name ? data?.user?.first_name : ""
                  } ${data?.user?.last_name ? data?.user?.last_name : ""}`,
                  Email: `${data?.user?.email ? data?.user?.email : ""}`,
                  "Display Name": `${
                    data?.user?.display_name ? data?.user?.display_name : "N/A"
                  }`,
                  Phone: `${
                    data?.user?.country_code ? data?.user?.country_code : ""
                  } ${data?.user?.phone ? data?.user?.phone : "N/A"}`,
                  Timezone: `${
                    data?.user?.timezone ? data?.user?.timezone : "N/A"
                  }`,
                  "Job title": `${
                    data?.user?.job_title ? data?.user?.job_title : "N/A"
                  }`,
                  Company: `${
                    data?.user?.company ? data?.user?.company : "N/A"
                  }`,
                  "Total Licenses": `${
                    data?.user?.total_licenses
                      ? data?.user?.total_licenses
                      : "N/A"
                  }`,
                }}
                // social={[]}
                // action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
