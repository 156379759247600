import axios from "axios";

export const SigninAction = async (user) => {
  axios.defaults.headers.post["Content-Type"] = "application/json";
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
      email: user.email,
      password: user.password,
      device_type: "web",
      desktop_version: "3.0.32",
    })
    .then((response) => {
      if (
        response.data.meta.code == "200" &&
        (response.data.data?.is_admin == 1 || 2)
      ) {
        const id = response.data.data.id;
        const user_id = response.data.data.id;
        const first_name = response.data.data.first_name;
        const last_name = response.data.data.last_name;
        const firstName = response.data.data.first_name;
        const lastName = response.data.data.last_name;
        const email = response.data.data.email;
        // const username = responce.data.data.username
        const language_id = response.data.data.language_id;
        const login_token = response.data.data.login_token;
        const profile_picture_url = response.data.data.profile_picture_url;
        const profile_pic = response.data.data.profile_picture_url;
        const call_config = response.data.data.call_config;
        const admin = response.data.data?.is_admin;
        const loggedInUser = {
          id,
          first_name,
          last_name,
          firstName,
          lastName,
          user_id,
          email,
          language_id,
          login_token,
          login_session_id: response.data.data.login_session_id,
          profile_picture_url,
          profile_pic,
          call_config,
          admin
        };

        localStorage.setItem("user", JSON.stringify(loggedInUser));
        return loggedInUser;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const LogoutAction = (user_id) => {
  var promise = axios.post("auth/logout").then((resp) => {
    localStorage.clear();
    return resp;
  });
  return promise;
};

export const UserTableData = (token, id) => {
  const promise = axios
    .get("subscriptions/user_subscription_list", {
      params: {
        plan_id: id,
        token,
      },
      headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
      console.log(response );
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
  return promise;
};

export const UserSignUpTableData = (token, data) => {
  const promise = axios
    .get("admin/user/user_signup_listing", {
      params: data,
      headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
  return promise;
};


export const UserSignUpBillingData = (token, data) => {
  const promise = axios
    .get("admin/billing/user_billing_listing", {
      params: data,
      headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
  return promise;
};


export const UserViewBillingData = (token, data) => {
  const promise = axios
    .get("admin/billing/user_billing_detail", {
      params: data,
      headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
      console.log(response , "responseresponse");
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
  return promise;
};


export const UserStatus = (token, statusRespId, userAction) => {
  const promise = axios
    .get("users/change_user_status", {
      params: {
        user_id: statusRespId,
        status: userAction,
      },
      headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
  return promise;
};
export const UserCount = (token) => {
  const promise = axios
    .get("subscriptions/user_subscriptions_count", {
      headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
  return promise;
};

// export const PlanCategoryData = (token, id) => {
//   const promise = axios
//     .get(`${process.env.REACT_APP_API_URL}/plans/get_all_plan_categories`, {
//       // params: {
//       //   plan_id: id,
//       //   token,
//       // },
//       headers: { Authorization: "Bearer " + token },
//     })
//     .then((response) => {
//       console.log(response);
//       return response;
//     })
//     .catch((error) => {
//       console.log(error);
//     });
//   return promise;
// };

export const PlanCategoryData = (token) => {
  const promise = axios
    .get("plans/get_all_plan_categories", {
      headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
  return promise;
};

export const PlanData = (token) => {
  const promise = axios
    .get("plans/get_all_plan", {
      headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
  return promise;
};
