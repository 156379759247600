import React from "react";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
function FullPageLoader() {
  return (
    <div className="loader-container">
      <Backdrop style={{ zIndex: 1310, color: "#fff" }} open>
        <CircularProgress color="inherit" style={{ zIndex: 2 }} />
      </Backdrop>
    </div>
  );
}

export default FullPageLoader;
