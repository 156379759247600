import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "./FormDialog.css";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { PlanData } from "components/Samples";
import "./UpgradePlan.css";
import axios from "axios";
import { useMaterialUIController } from "context";
import "./UpgradePlan.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
export default function UpgraderPlan({
  open,
  handleClose,
  userData,
  getTableData,
}) {
  const { user_id } = userData;
  const [loader, setLoader] = useState(false);
  const [planName, setplanName] = useState("");
  const [planStatus, setplanStatus] = useState("");
  const [controller, dispatch] = useMaterialUIController();
  const [planList, setPlansList] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(userData?.plan_id);
  const { userAuth } = controller;
  const token = userAuth.login_token;
  const [CurrentPlanSelect, setCurrentPlanSelect] = useState("");

  console.log(userData?.plan_id, "userDatauserData")

  useEffect(() => {
    setCurrentPlan(userData?.plan_id);
  }, [userData?.plan_id]);
  useEffect(() => {
    const actionResp = PlanData(token);

    actionResp
      .then((resp) => {
        const data = resp.data.data;
        setPlansList(data);
        if (resp.status) {
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        return error;
      });
  }, []);

  const handlePlanName = (e) => {
    setplanName(e.target.value);
  };
  const handleplanStatus = (event) => {
    setCurrentPlan(parseInt(event.target.value));
  };

  const handleUpdatePlane = () => {
    const formData = new FormData();
    formData.append("plan_id", parseInt(currentPlan)); // Assuming user_id is available in your code
    formData.append("user_id", parseInt(user_id));
    console.log(formData);

    for (let pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1], "pairrr");
    }

    axios
      .post("subscriptions/update_subscription_plan", formData, {
        headers: { token },
      })
      .then((res) => {
        handleClose();
        if (res.data.meta.code == 200) {
          getTableData();
          toast.success(<h6>User's plan updated successfully</h6>);
        }
      })
      .catch((err) => {
        handleClose();
        toast.error(<h6>Something went wrong</h6>);
        console.log(err);
      });
  };

  return (
    <div>
      {!loader ? (
        <Dialog
          open={open}
          onClose={handleClose}
          style={{ overflowY: "scroll" }}
        >
          <DialogTitle
            display="flex"
            justifyContent="center"
            className="plan-upgrader-main-wrapper"
          >
            Upgrade Plan
          </DialogTitle>
          <DialogContent>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                type="text"
                defaultValue={
                  userData?.user?.first_name + "" + userData?.user?.last_name
                }
                fullWidth
                variant="standard"
                className="Meeting_heading"
                disabled
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                    color: "#000000",
                  },
                }}

              // value={planName}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Email"
                type="text"
                defaultValue={userData?.user?.email}
                fullWidth
                variant="standard"
                className="Meeting_heading"
                disabled
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                    color: "#000000",
                  },
                }}

              // value={planName}
              />
            </div>

            <div>
              <FormControl fullWidth>
                <InputLabel
                  variant="standard"
                  htmlFor="uncontrolled-native"
                  className="Meeting_heading"
                >
                  Select Status
                </InputLabel>
                <NativeSelect
                  defaultValue={userData?.plan_id}
                  inputProps={{
                    name: "age",
                    id: "uncontrolled-native",
                  }}
                  className="Meeting_heading"
                  onChange={(event) => handleplanStatus(event)}
                >
                  {planList?.map((plan) => {
                    return (
                      <option key={plan.id} value={plan.id}>
                        {plan.name}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </div>

            <div className="plan-upgrader-btn">
              <button onClick={handleUpdatePlane}>Upgrade</button>
            </div>
          </DialogContent>
        </Dialog>
      ) : (
        <FullPageLoader />
      )}
    </div>
  );
}
