import { useState, useEffect, useMemo, Fragment } from "react";
import {
  Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./PrivateRoute";
import SignIn from "layouts/authentication/sign-in";
import Dashboard from "layouts/dashboard";
import Basic from "layouts/tables/basic";
import Pro from "layouts/tables/Pro";
import Business from "layouts/tables/business";
import Enterprise from "layouts/tables/Enterprise";
import Plan from "layouts/tables/Plan";
import PlanCategory from "layouts/tables/PlanCategory";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import * as common from "./assets/js/helpers/common";
import "./styles.scss";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import axios from "axios";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import routes from "routes";
import Profile from "./layouts/profile";
import "bootstrap/dist/css/bootstrap.min.css";
require("./assets/js/security/handle_encryption");
import {
  useMaterialUIController,
  setMiniSidenav,
  setUserLoginRequest,
  setOpenConfigurator,
} from "context";
import PlanPopUp from "./components/popup/PlanPopUp";
// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import AddNewUser from "components/pages/Users/AddNewUser";
import SignUpListing from "layouts/tables/SignUpListing";
import UserBilling from "layouts/tables/UserBilling";
import UserDetail from "layouts/tables/UserDetail";

// intercepting request to encrypt request data
axios.interceptors.request.use(function (config) {
  var current_user = common.getCurrentUser();

  if ((config.url.indexOf("https") == -1, config.url.indexOf("http") == -1)) {
    var url = config.url;
    //removing first slash if any
    if (url.indexOf("/") === 0) {
      url = url.substring(1);
    }

    config.url = process.env.REACT_APP_API_URL + "/" + url;

    config.headers.Authorization = "Bearer dummy_token";
    //window.old_user is available temprarily after logout, to make some apis work after logout like end call apis
    config.headers.token =
      current_user.login_token ||
      (window.old_user ? window.old_user.login_token : "");
    console.log("encryption done");
  }

  return config;
});

//intercepting response
axios.interceptors.response.use(function (response) {
  if (response && response.data.meta && response.data.meta.code == "401") {
    localStorage.clear();
    document.location.reload();
  }

  return response;
});

function routeComponent() {
  return (
    <Fragment>
      <Routes>
        <Route exact path="/" element={<PrivateRoute />}>
          <Route
            exact
            path="/dashboard"
            key="dashboard"
            element={<Dashboard />}
          />
          <Route
            exact
            path="/tables/basic-members"
            key="tables-basic-members"
            element={<Basic />}
          />
          <Route
            exact
            path="/tables/pro-members"
            key="tables-pro-members"
            element={<Pro />}
          />
          <Route
            exact
            path="/tables/business-members"
            key="tables-business-members"
            element={<Business />}
          />
          <Route
            exact
            path="/tables/business-pro-members"
            key="tables-business-pro-members"
            element={<Enterprise />}
          />
          <Route
            exact
            path="/tables/plan-categories"
            key="tables/plan-categories"
            element={<PlanCategory />}
          />
          <Route
            exact
            path="/tables/plan"
            key="tables/plan"
            element={<Plan />}
          />
          <Route exact path="/addplan" key="Plan" element={<PlanPopUp />} />
          <Route
            path="/add-new-user"
            key="/add-new-user"
            element={<AddNewUser />}
          />

          <Route
            path="/view-profile"
            key="/view-profile"
            element={<Profile />}
          />
          <Route
            exact
            path="/tables/sign-up-listing"
            key="sign-up-listing"
            element={<SignUpListing />}
          />
          <Route
            exact
            path="/tables/user-billing"
            key="user-billing"
            element={<UserBilling />}
          />
          <Route
            path={`/tables/user-billing/:id`}
            key="Billing detail"
            element={<UserDetail />}
          />

          {/* <Route exact path="/Pricing" key="Pricing" element={<Pricing />} /> */}
        </Route>
        <Route path="*" element={<Dashboard />} />

        <Route path="/sign-in" element={<SignIn />} key="sign-in" />
        {/* <Route path="/profile" element={<Profile />} key="Profile" /> */}
      </Routes>
      <ToastContainer />
    </Fragment>
  );
}
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const auth = common.getCurrentUser();
  let navigate = useNavigate();
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });
  useEffect(() => {
    if (auth) {
      setUserLoginRequest(dispatch, auth);
      return navigate("/dashboard");
    }
  }, []);

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Material Dashboard 2"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
          </>
        )}
        {layout === "vr" && <Configurator />}
        {routeComponent()}
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="MIZDAH"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
        </>
      )}
      {layout === "vr" && <Configurator />}
      {routeComponent()}
    </ThemeProvider>
  );
}
