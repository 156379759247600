import React, { useState, useEffect, useRef } from "react";
import green from "@material-ui/core/colors/grey";
import message from "../../assets/images/message.png";
import Vector from "../../assets/images/Vector.png";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { ToastContainer } from "react-toastify";
import {
  Container,
  TextField,
  Card,
  CardContent,
  MenuItem,
  Avatar,
  Grid,
  IconButton,
  InputLabel,
  Select,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import {
  makeStyles,
  withStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { defaultTheme, theme } from "../../assets/js/custom-color";
import PrimaryButton from "./PrimaryButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CloseIcon from "@material-ui/icons/Close";

import "./profile.css";

const Profile = ({ profile, setProfile }) => {
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    getProfile();
    // cancel subscription to useEffect
    return () => (isSubscribed = false);
  }, []);
  let completeUser = localStorage.getItem("user");
  let auth = JSON.parse(completeUser);
  console.log(auth);
  const initialProfileState = {
    firstName: "",
    lastName: "",
    email: "",
    language_id: "",
    profile_pic: "",
    username: "",
  };
  const [currentProfile, setCurrentProfile] = useState(initialProfileState);

  let user = {
    firstName: auth.first_name,
    lastName: auth.last_name,
    email: auth.email,
    language_id: auth.language_id,
    profile_pic: auth.profile_picture_url,
    username: auth.username,
  };

  const getProfile = () => {
    setCurrentProfile(user);
  };

  const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      "&$checked": {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const handleLanguageChange = (value) => {
    setLanguage(value);
    localStorage.setItem("locale", value);

    document.documentElement.lang = value;
  };

  const handleClose = () => {
    setProfile(false);
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.checked });
  };

  const uploadfile = (e) => {
    setLoader(true);
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("directory", "users");
    formData.append("file", file);
    formData.append("ignore_encryption", true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post("file_storage/upload_file", formData, config)
      .then((responce) => {
        console.log(responce.data.data[0]);
        const profile_url = responce.data.data[0];
        let currentProfileLocal = {
          ...currentProfile,
          [e.target.name]: profile_url,
        };
        setCurrentProfile({ ...currentProfileLocal });
        var existing_user_object = localStorage.getItem("user");
        const parsed_existing_user_object = JSON.parse(existing_user_object);
        parsed_existing_user_object.profile_picture_url = profile_url;
        parsed_existing_user_object.profile_pic = profile_url;
        const modified_existing_user_object = JSON.stringify(
          parsed_existing_user_object
        );
        localStorage.setItem("user", modified_existing_user_object);
        if (responce.status === 200) {
          let formData = new FormData();
          formData.append("profile_picture_url", profile_url);
          formData.append("first_name", currentProfile.firstName);
          formData.append("last_name", currentProfile.lastName);
          formData.append("auto_translate", 1);
          formData.append("email", currentProfile.email);
          formData.append("language_id", currentProfile.language_id);

          axios
            .post("profile/update", formData)
            .then((responce) => {
              console.log(responce);
              if (responce.data.meta.code == "200") {
                const User = {
                  email: responce.data.data.email,
                  firstName: responce.data.data.first_name,
                  language_id: responce.data.data.language_id,
                  lastName: responce.data.data.last_name,
                  profile_pic: responce.data.data.profile_picture_url,
                };
                setCurrentProfile(User);
                var existing_user_object = localStorage.getItem("user");
                console.log(existing_user_object);
                const parsed_existing_user_object =
                  JSON.parse(existing_user_object);
                const { profile_pic, firstName, lastName, email } = User;
                parsed_existing_user_object.profile_picture_url = profile_pic;
                parsed_existing_user_object.first_name = firstName;
                parsed_existing_user_object.last_name = lastName;
                parsed_existing_user_object.email = email;
                const modified_existing_user_object = JSON.stringify(
                  parsed_existing_user_object
                );
                localStorage.setItem("user", modified_existing_user_object);
                setLoader(false);
                toast.success(<h6>Profile updated successfully</h6>);
                setProfile(false);
              } else {
                toast.error(<h6>{responce.data.meta.message}</h6>);
                setLoader(false);
                setProfile(false);
              }
            })
            .catch((error) => {
              console.log(error);
              setLoader(false);
              setProfile(false);
            });

          setLoader(false);
          setProfile(false);
          toast.success(<h6>Profile updated successfully</h6>);
        } else {
          toast.error(responce.data.meta.message);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        setProfile(false);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentProfile({ ...currentProfile, [name]: value });
  };

  const submitForm = (e) => {
    setLoader(true);
    e.preventDefault();

    console.log("abdullah", currentProfile.firstName);
    let formData = new FormData();
    formData.append("profile_picture_url", currentProfile.profile_pic);
    formData.append("first_name", currentProfile.firstName);
    formData.append("last_name", currentProfile.lastName);
    formData.append("auto_translate", 1);
    formData.append("email", currentProfile.email);
    formData.append("language_id", currentProfile.language_id);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post("profile/update", formData)
      .then((responce) => {
        console.log(responce);
        if (responce.data.meta.code == "200") {
          const User = {
            email: responce.data.data.email,
            firstName: responce.data.data.first_name,
            language_id: responce.data.data.language_id,
            lastName: responce.data.data.last_name,
            profile_pic: responce.data.data.profile_picture_url,
          };
          setCurrentProfile(User);
          var existing_user_object = localStorage.getItem("user");
          console.log(existing_user_object);
          const parsed_existing_user_object = JSON.parse(existing_user_object);
          const { profile_pic, firstName, lastName, email } = User;
          parsed_existing_user_object.profile_picture_url = profile_pic;
          parsed_existing_user_object.first_name = firstName;
          parsed_existing_user_object.last_name = lastName;
          parsed_existing_user_object.email = email;
          const modified_existing_user_object = JSON.stringify(
            parsed_existing_user_object
          );
          localStorage.setItem("user", modified_existing_user_object);
          setLoader(false);
          toast.success(<h6>Profile updated successfully</h6>);
          setProfile(false);
        } else {
          toast.error(<h6>{responce.data.meta.message}</h6>);
          setLoader(false);
          setProfile(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        setProfile(false);
      });
  };

  const file = useRef(null);
  const addImageClicked = () => {
    if (file.current) {
      file.current.click();
    }
  };

  const useStyles = makeStyles((theme) => ({
    papers: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyItems: "center",
      paddingInlineStart: "0rem",
      paddingInlineEnd: "0rem",
      paddingTop: "0",
      paddingBottom: "1rem",
    },
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    form: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      // Fix IE 11 issue.
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    checkboxLabel: {
      color: "#495057",
      fontSize: "0.8rem",
      verticalAlign: "middle",
    },
    label: {
      fontSize: "0.8rem",
      fontWeight: "300",
    },
    select: {
      paddingTop: "0.4rem",
      paddingBottom: "0.4rem",
      paddingInlineStart: "0.4rem !important",
      paddingInlineEnd: "0.4rem",
    },

    outlined: {
      paddingInlineEnd: "0.4rem !important",
      borderRadius: "1rem",
      color: "#93C0FF",
      borderColor: "#93C0FF",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #000",
      padding: theme.spacing(2, 4, 3),
      width: "43%",
      borderRadius: "25px",
      minWidth: "600px",
      height: "515px",
      overflow: "auto",
    },
    pbutton: {
      marginTop: "1rem",
      color: "#fff",
      backgroundColor: "#0057D3 !important",
      borderRadius: "12px",
      padding: "10px",
      textTransform: "none",
      width: "222px",
      height: "48px",
    },
    notchedOutline: {
      border: "1px solid #93C0FF !important",
      borderColor: "#93C0FF !important",
      borderRadius: "8px",
    },
  }));

  const classes = useStyles();

  return (
    <div>
      {!loader ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={profile}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={profile}>
            <div className={classes.paper} className="profilewidth">
              <ThemeProvider theme={defaultTheme}>
                <Container
                  component="main"
                  style={{
                    backgroundRepeat: "repeat",
                  }}
                >
                  <Grid container>
                    <Grid item md={12} xs={12}>
                      <Grid container justify="center" alignItems="center">
                        <Grid item md={10} xs={12}>
                          <div className="root">
                            <Grid
                              container
                              direction="row"
                              justify="center"
                              alignItems="center"
                              style={{ marginLeft: "-20px" }}
                            >
                              <Grid
                                className="profile-text"
                                item
                                lg={10}
                                sm={10}
                              >
                                <Typography
                                  variant="h6"
                                  component="h6"
                                  style={{
                                    color: "#000",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  {"Profile"}
                                </Typography>
                              </Grid>
                              <Grid item lg={1} sm={1}>
                                <button
                                  className="closeBtnProfile"
                                  onClick={handleClose}
                                  style={{ cursor: "pointer" }}
                                >
                                  <CloseIcon className="CloseIcon" />
                                </button>
                              </Grid>
                            </Grid>
                          </div>

                          <div className={classes.papers}>
                            <img
                              src={auth.profile_picture_url}
                              className="img-size-profile"
                              onClick={addImageClicked}
                            />
                            <form>
                              <span className="add-imge">
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={uploadfile}
                                  ref={file}
                                  style={{ display: "none" }}
                                  name="profile_pic"
                                />
                                <img
                                  className="imageuplode"
                                  onClick={addImageClicked}
                                  src={Vector}
                                />
                              </span>
                            </form>

                            <div>
                              <div className="fname-lname" fullWidth>
                                <FormControl margin="normal">
                                  <Typography className="name-fild">
                                    {"First_Name"}
                                  </Typography>
                                  <TextField
                                    className="first_Name"
                                    variant="outlined"
                                    id="firstName"
                                    // label={"First Name"}
                                    placeholder="First Name"
                                    name="firstName"
                                    autoFocus
                                    value={""}
                                    onChange={handleInputChange}
                                    classes={{ root: classes.label }}
                                    type="text"
                                    InputProps={{
                                      value: currentProfile
                                        ? currentProfile.firstName
                                        : "",
                                      classes: {
                                        notchedOutline: classes.notchedOutline,
                                      },
                                    }}
                                  />
                                </FormControl>
                                <FormControl margin="normal">
                                  <Typography className="name-fild">
                                    {"Last_Name"}
                                  </Typography>
                                  <TextField
                                    className="Last_Name"
                                    variant="outlined"
                                    placeholder={"Last Name"}
                                    id="lastName"
                                    // label={t("Last Name")}
                                    name="lastName"
                                    autoFocus
                                    value={""}
                                    onChange={handleInputChange}
                                    classes={{ root: classes.label }}
                                    type="text"
                                    InputProps={{
                                      value: currentProfile
                                        ? currentProfile.lastName
                                        : "",
                                      classes: {
                                        notchedOutline: classes.notchedOutline,
                                      },
                                    }}
                                  />
                                </FormControl>
                              </div>

                              <FormControl margin="normal">
                                <Typography className="name-fild">
                                  {" "}
                                  {"E_Mail"}
                                </Typography>
                                <TextField
                                  className="E-mail"
                                  variant="outlined"
                                  placeholder={"Email Address"}
                                  id="email"
                                  // label={t("Email Address")}
                                  name="email"
                                  autoFocus
                                  value={""}
                                  onChange={handleInputChange}
                                  classes={{ root: classes.label }}
                                  type="email"
                                  InputProps={{
                                    readOnly: true,
                                    value: currentProfile
                                      ? currentProfile.email
                                      : "",
                                    startAdornment: (
                                      <InputAdornment
                                        position="start"
                                        className="icons"
                                      >
                                        <img
                                          className="messagepic"
                                          src={message}
                                          alt="message"
                                        />
                                      </InputAdornment>
                                    ),
                                    classes: {
                                      notchedOutline: classes.notchedOutline,
                                    },
                                  }}
                                />
                              </FormControl>

                              {/* <div>
                                {" "}
                                <FormControl margin="normal">
                                  <Typography className="name-fild">
                                    {" "}
                                    {"Password"}
                                  </Typography>
                                  <TextField
                                    className="E-mail"
                                    variant="outlined"
                                    placeholder={"Password"}
                                    id="password"
                                    // label={t("Email Address")}
                                    name="Password"
                                    autoFocus
                                    value={""}
                                    onChange={handleInputChange}
                                    classes={{ root: classes.label }}
                                    type="Password"
                                    InputProps={{
                                      readOnly: true,
                                      value: currentProfile
                                        ? currentProfile.email
                                        : "",
                                      startAdornment: (
                                        <InputAdornment
                                          position="start"
                                          className="icons"
                                        >
                                          <LockOpenIcon
                                            style={{
                                              fontSize: 16,
                                              color: "#0057D3",
                                            }}
                                          />
                                        </InputAdornment>
                                      ),
                                      classes: {
                                        notchedOutline: classes.notchedOutline,
                                      },
                                    }}
                                  />
                                </FormControl>
                              </div> */}
                              <div className="button-update">
                                <ThemeProvider theme={theme}>
                                  <PrimaryButton
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    fullWidth
                                    classes={{
                                      root: classes.pbutton,
                                    }}
                                  >
                                    <span onClick={submitForm}>{"Update"}</span>{" "}
                                  </PrimaryButton>
                                </ThemeProvider>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </ThemeProvider>
            </div>
          </Fade>
        </Modal>
      ) : (
        <FullPageLoader />
      )}
      {/* <FullPageLoader /> */}
      <ToastContainer />
    </div>
  );
};

export default Profile;
