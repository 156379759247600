import React, { useState, useMemo, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FilteredComponent from "components/FilteredComponent";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  useMaterialUIController,
  setMiniSidenav,
  setUserLoginRequest,
  setWhiteSidenav,
} from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { FcNext } from "react-icons/fc";

import {
  UserTableData,
  UserStatus,
  UserSignUpTableData,
} from "components/Samples";
import { FiFilter } from "react-icons/fi";
import Footer from "examples/Footer";
import Swal from "sweetalert2/dist/sweetalert2.js";
import DataTable from "react-data-table-component";
import { useTable, usePagination } from "react-table";
import UpgraderPlan from "components/popup/UpgradePlan";
import { Link } from "react-router-dom";
import axios from "axios";
import { FcPrevious } from "react-icons/fc";

import Moment from "moment";

function SignUpListing() {
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [controller, dispatch] = useMaterialUIController();
  const { userAuth } = controller;
  const token = userAuth.login_token;
  const [count, setCount] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [FilterName, setFilterName] = useState("");
  const [FilterEmail, setFilterEmail] = useState("");
  const [pageNumber, setpageNumber] = useState(0);
  const [start_date, set_start_date] = useState(
    Moment().subtract(2, "month").format("YYYY-MM-DD")
  );
  const [end_date, set_end_date] = useState(
    Moment().add(2, "day").format("YYYY-MM-DD")
  );

  console.log(start_date, end_date);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [open, setOpen] = useState(false);

  const handleDateEndChange = (e) => {
    set_end_date(e.target.value);
  };

  const handleDateChange = (e) => {
    set_start_date(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Pagination",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
  };
  const columns = [
    {
      name: "Profile Pic",
      selector: (row) => (
        <img
          className="table-image"
          src={row?.profile_picture_url}
          alt="image description"
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      name: "Name",
      selector: (row) => {
        const MAX_NAME_LENGTH = 20;
        return row?.first_name?.length > MAX_NAME_LENGTH
          ? row?.first_name?.substring(0, MAX_NAME_LENGTH) + "..."
          : row?.first_name;
      },
    },
    {
      name: "Email",
      selector: (row) => row?.email,
    },
    {
      name: "Phone",
      selector: (row) => (row?.phone ? row?.country_code + row?.phone : "N/A"),
    },
    {
      name: "Created At",
      selector: (row) =>
        row?.created_at ? Moment(row?.created_at).format("YYYY-MM-DD") : "N/A",
    },
    // {
    //   name: "Company",
    //   selector: (row) => (row?.company ? row?.company : "N/A"),
    // },
  ];

  const handleFilterApply = () => {
    const userdata = {
      start_date: start_date,
      end_date: end_date,
      name: FilterName,
      email: FilterEmail,
      page: 0,
      limit: 20,
    };
    const actionResp = UserSignUpTableData(token, userdata);

    actionResp
      .then((resp) => {
        const data = resp.data.data;
        setShowFilters(false);
        setTableData(data);
        if (resp.status) {
          setLoader(false);
        }
      })
      .catch((error) => {
        setShowFilters(false);
        console.log(error);
        setLoader(false);
        return error;
      });
  };

  const handleFilteClear = () => {
    setFilterEmail("");
    setFilterName("");
    set_start_date(Moment().subtract(2, "month").format("YYYY-MM-DD"));
    set_end_date(Moment().add(2, "day").format("YYYY-MM-DD"));
    getTableClearData();
    setShowFilters(false);
  };



  const getTableClearData = () => {
    const userdata = {
      start_date: start_date,
      end_date: end_date,
      name: "",
      email: "",
      page: pageNumber, // Use pageNumber state variable here
      limit: 20,
    };

    const actionResp = UserSignUpTableData(token, userdata);

    actionResp
      .then((resp) => {
        const data = resp.data.data;
        setTableData(data);
        console.log(data, "datadata");
        if (resp.status) {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        return error;
      });
  };

  const getTableData = () => {
    const userdata = {
      start_date: start_date,
      end_date: end_date,
      name: FilterName,
      email: FilterEmail,
      page: pageNumber, // Use pageNumber state variable here
      limit: 20,
    };

    const actionResp = UserSignUpTableData(token, userdata);

    actionResp
      .then((resp) => {
        const data = resp.data.data;
        setTableData(data);
        console.log(data, "datadata");
        if (resp.status) {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        return error;
      });
  };

  useEffect(() => {
    getTableData();
  }, [pageNumber]); // Call getTableData whenever pageNumber changes

  const ResultfilteredItems = tableData?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  let filteredItems = ResultfilteredItems?.filter(
    (item) => item?.is_admin !== 1
  );
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilteredComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const nextPage = () => {
    setpageNumber(pageNumber + 1);
  };

  const prevPage = () => {
    if (pageNumber > 0) {
      setpageNumber(pageNumber - 1);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Sign Up User List
                </MDTypography>
                <div style={{ position: "relative" }}>
                  <FiFilter
                    onClick={() => setShowFilters(!showFilters)}
                    color="white"
                    cursor={"pointer"}
                  />
                  <div
                    style={{ display: showFilters ? "block" : "none" }}
                    className="user-sign-up-listing"
                  >
                    <div className="input-heading-wrapper">
                      <h4>Start Date</h4>
                      <input
                        type="date"
                        className="form-control payment-history-date-selection"
                        name="start_date"
                        value={start_date}
                        onChange={handleDateChange}
                        style={{ marginRight: "18px" }}
                      />
                    </div>
                    <div className="input-heading-wrapper">
                      <h4>End Date</h4>
                      <input
                        type="date"
                        className="form-control payment-history-date-selection"
                        name="start_date"
                        value={end_date}
                        onChange={handleDateEndChange}
                      />
                    </div>
                    <div className="input-heading-wrapper">
                      <h4>Name</h4>
                      <input
                        type="text"
                        className="form-control payment-history-name-selection"
                        name="start_date"
                        value={FilterName}
                        onChange={(e) => setFilterName(e.target.value)}
                      />
                    </div>
                    <div className="input-heading-wrapper">
                      <h4>Email</h4>
                      <input
                        type="text"
                        className="form-control payment-history-name-selection"
                        name="start_date"
                        value={FilterEmail}
                        onChange={(e) => setFilterEmail(e.target.value)}
                      />
                    </div>
                    <div
                      className="input-heading-wrapper"
                      style={{ display: "flex" }}
                    >
                      <button
                        className="filter-add-btn"
                        onClick={handleFilterApply}
                      >
                        Apply
                      </button>
                      <button
                        className="filter-add-btn"
                        onClick={handleFilteClear}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>

                {/* <Link to="/add-new-user">
                  <button variant="h6" color="white" className="add-btn">
                    Add User
                  </button>
                </Link> */}
              </MDBox>
              {loader ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <MDBox pt={3}>
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      subHeader
                      subHeaderComponent={subHeaderComponent}
                    />
                  </MDBox>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                    className="fileters-bottom-warrper"
                  >
                    <button
                      className="fileters-left-heading fileters-button"
                      onClick={prevPage}
                    >
                      Prev <FcPrevious />
                    </button>
                    <div className="fileters-left-heading fileters-count-number ">
                      Page {pageNumber + 1}
                    </div>
                    <button
                      className="fileters-left-heading fileters-button"
                      onClick={nextPage}
                    >
                      <FcNext /> Next
                    </button>
                  </div>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default SignUpListing;
