import Dashboard from "layouts/dashboard";
import Basic from "layouts/tables/basic";
import Pro from "layouts/tables/Pro";
import Business from "layouts/tables/business";
import Enterprise from "layouts/tables/Enterprise";

import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Pricing from "layouts/pricing";
import plan from "./assets/images/plan.png";

// @mui icons
import Icon from "@mui/material/Icon";
import { FaUserAstronaut, FaUserSecret, FaUsers } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { SiPlanet } from "react-icons/si";
import { BiCategory } from "react-icons/bi";
import Plan from "layouts/tables/Plan";
import SignUpListing from "layouts/tables/SignUpListing";
import UserBilling from "layouts/tables/UserBilling";
import UserDetail from "layouts/tables/UserDetail";
const routes = [
  {
    type: "collapse",
    routeType: "Private",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    routeType: "Private",
    name: "Basic Members",
    key: "tables/basic-members",
    icon: <FaUsers />,
    route: "/tables/basic-members",
    component: <Basic />,
  },
  {
    type: "collapse",
    routeType: "Private",
    name: "Pro Members",
    key: "tables/pro-members",
    icon: <FaUserSecret />,
    route: "/tables/pro-members",
    component: <Pro />,
  },
  {
    type: "collapse",
    routeType: "Private",
    name: "Business Members",
    key: "tables/business-members",
    icon: <FaUserAstronaut />,
    route: "/tables/business-members",
    component: <Business />,
  },
  {
    type: "collapse",
    routeType: "Private",
    name: "Business Pro Members",
    key: "tables/business-pro-members",
    icon: <ImUsers />,
    route: "/tables/business-pro-members",
    component: <Enterprise />,
  },
  // {
  //   type: "collapse",
  //   routeType: "Private",
  //   name: "Plan Categories",
  //   key: "tables/plan-categories",
  //   icon: <BiCategory />,
  //   route: "/tables/plan-categories",
  //   component: <Enterprise />,
  // },
  {
    type: "collapse",
    routeType: "Private",
    name: "Plan",
    key: "tables/plan",
    icon: <SiPlanet />,
    route: "/tables/plan",
    component: <Plan />,
  },
  {
    type: "collapse",
    routeType: "Private",
    name: "Sign up users listing",
    key: "sign-up-listing",
    icon: <SiPlanet />,
    route: "/tables/sign-up-listing",
    component: <SignUpListing />,
  },
  {
    type: "collapse",
    routeType: "Private",
    name: "User Billing detail",
    key: "user-billing",
    icon: <SiPlanet />,
    route: "/tables/user-billing",
    component: <UserBilling />,
  },
  // {
  //   type: "collapse",
  //   routeType: "Private",
  //   name: "Pricing",
  //   key: "Pricing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/Pricing",
  //   component: <Pricing />,
  // },
  // {
  //   type: "collapse",
  //   routeType: "Private",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   routeType: "Private",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   routeType: "Private",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  // {
  //   type: "collapse",
  //   routeType: "Public",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   type: "collapse",
  //   routeType: "Public",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
];

export default routes;
