import { useState, useMemo, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FilteredComponent from "components/FilteredComponent";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import axios from "axios";
import "./plan.css";
import {
  useMaterialUIController,
  setMiniSidenav,
  setUserLoginRequest,
  setWhiteSidenav,
} from "context";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { UserTableData, UserStatus, PlanData } from "components/Samples";
import Footer from "examples/Footer";
import Swal from "sweetalert2/dist/sweetalert2.js";
import DataTable from "react-data-table-component";
import { useTable, usePagination } from "react-table";
import PlanPopUp from "../../components/popup/PlanPopUp";
import { Link } from "react-router-dom";
function Enterprise() {
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [controller, dispatch] = useMaterialUIController();
  const { userAuth } = controller;
  const token = userAuth.login_token;
  const [count, setCount] = useState(0);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [open, setOpen] = useState(false);
  const auth = JSON.parse(localStorage.getItem('user'));
  const admin_type = auth?.admin
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleButtonClick = (e, id) => {
    const plan_id = id?.id;

    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Plan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        const data = JSON.stringify({
          plan_id,
        });

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "admin/plans/delete_plan",
          headers: {
            token: token,
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer asd",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            console.log(response, data, "responseresponse");
            Swal.fire("Deleted!", "Plan has been deleted.", "success");
            setCount(count + 1);
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error!", "Failed to delete plan.", "error");
          });
      }
    });
  };
  const paginationComponentOptions = {
    rowsPerPageText: "Pagination",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Price",
      selector: (row) => row.price,
    },
    {
      name: "Min License",
      selector: (row) => row.min_licenses,
    },
    {
      name: "Max License",
      selector: (row) => row.max_licenses,
    },
    {
      name: "Max Call Participant",
      selector: (row) => row.call_max_participants,
    },
    {
      name: "Max Call Minutes",
      selector: (row) => row.call_max_minutes,
    },
    {
      name: "Price Per License",
      selector: (row) => row.price_per_license,
    },
    admin_type==1 &&{
      name: "Action",
      cell: (data) => (
        <button
          onClick={(e) => handleButtonClick(e, data)}
          className={"active-btn"}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    admin_type==1 &&{
      name: "Action",
      cell: (data) => (
        <Link to="/addplan" state={data}>
          {" "}
          <button className={"active-btn"}>Edit</button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const filtrecolumns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Price",
      selector: (row) => row.price,
    },
    {
      name: "Min License",
      selector: (row) => row.min_licenses,
    },
    {
      name: "Max License",
      selector: (row) => row.max_licenses,
    },
    {
      name: "Max Call Participant",
      selector: (row) => row.call_max_participants,
    },
    {
      name: "Max Call Minutes",
      selector: (row) => row.call_max_minutes,
    },
    {
      name: "Price Per License",
      selector: (row) => row.price_per_license,
    },
  ];
  useEffect(() => {
    const actionResp = PlanData(token);

    actionResp
      .then((resp) => {
        const data = resp.data.data;
        setTableData(data);
        console.log(data, "datadata");
        if (resp.status) {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        return error;
      });
  }, [count]);
  const ResultfilteredItems = tableData?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilteredComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" color="white">
                    Plan
                  </MDTypography>
                 { admin_type==1 &&  <Link to="/addplan">
                    {" "}
                    <button variant="h6" color="white" className="add-btn">
                      Add
                    </button>
                  </Link>}
                </MDBox>

                {loader ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50vh",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <MDBox pt={3}>
                    <DataTable
                     columns={admin_type == 1 ?  columns: filtrecolumns}
                      data={ResultfilteredItems}
                      pagination
                      paginationComponentOptions={paginationComponentOptions}
                      subHeader
                      subHeaderComponent={subHeaderComponent}
                    />
                  </MDBox>
                )}
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default Enterprise;
