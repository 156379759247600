import { useState, useMemo, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import axios from "axios";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import "./PopUp.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { Formik, Form, Field } from "formik";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FilteredComponent from "components/FilteredComponent";

import { useFormik } from "formik";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  useMaterialUIController,
  setMiniSidenav,
  setUserLoginRequest,
  setWhiteSidenav,
} from "context";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { UserTableData, PlanCategoryData } from "components/Samples";
import Footer from "examples/Footer";
import Swal from "sweetalert2/dist/sweetalert2.js";
import DataTable from "react-data-table-component";
import { useTable, usePagination } from "react-table";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import data from "layouts/tables/data/authorsTableData";
function PlanPopUp({ edit }) {
  const location = useLocation();
  const data = location.state;
  console.log(data);

  const [loader, setLoader] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { userAuth } = controller;
  const token = userAuth.login_token;
  const [count, setCount] = useState(0);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [planName, setPlanName] = useState("");
  const [planCategory, setPlanCategory] = useState(
    data && data.plan_category_id ? data.plan_category_id : 1
  );
  const [price, setPrice] = useState("");
  const [pricePerLicense, setpricePerLicense] = useState("");
  const [minLicense, setminLicense] = useState("");
  const [maxLicense, setmaxLicense] = useState("");
  const [callParticipant, setcallParticipant] = useState("");
  const [callMaxMinutes, setcallMaxMinutes] = useState("");
  const [TablePlanData, setTablePlanData] = useState([]);

  const [planCategoryName, setplanCategoryName] = useState();
  const PlanSchema = Yup.object().shape({
    planName: Yup.string()
      .min(2, "Plan Name Too Short!")
      .max(50, "Plan Name Too Long!")
      .required("Plan Name is Required"),
    price: Yup.number()
      .min(0, "Price  should be greater than 0")
      .required("Price is Required"),
    pricePerLicense: Yup.number()
      .min(0, "Price per License should be greater than 0")
      .required("Price Per License, is Required"),
    minLicense: Yup.number()
      .min(0, "Minimum License should be greater than 0")
      .required("Minimum License is Required"),
    maxLicense: Yup.number()
      .min(minLicense, "Maximum License should be greater than Minimum License")
      .required("Maximum License is Required"),
    callParticipant: Yup.number()
      .min(0, "Call Participant should be greater than 0")
      .required("Call Participants is Required"),
    callMaxMinutes: Yup.number()
      .min(0, "Call Participant should be greater than 0")
      .required("Call  Minutes is Required"),
  });
  var a = !data ? false : true;

  const formik = useFormik({
    initialValues: {
      planName: data && data.name ? data.name : "",
      price:
        data && data.monthly_price_per_license
          ? data.monthly_price_per_license
          : "",
      pricePerLicense:
        data && data.price_per_license ? data.price_per_license : "",
      minLicense: data && data.min_licenses ? data.min_licenses : "",
      maxLicense: data && data.max_licenses ? data.max_licenses : "",
      callParticipant:
        data && data.call_max_participants ? data.call_max_participants : "",
      callMaxMinutes:
        data && data.call_max_minutes ? data.call_max_minutes : "",
    },
    validationSchema: PlanSchema,

    onSubmit: (values) => {
      if (a === false) {
        setLoader(true);
        var planData = JSON.stringify({
          plan_name: values.planName,
          monthly_price_per_license: values.price,
          plan_price_per_license: values.pricePerLicense,
          plan_min_licenses: values.minLicense,
          plan_max_licenses: values.maxLicense,
          plan_call_max_participants: values.callParticipant,
          plan_call_max_minutes: values.callMaxMinutes,
        });

        var config = {
          method: "post",
          url: "admin/plans/add_plan",
          headers: {
            token: token,
            Accept: "application/json",
            Authorization: "Bearer asd",
            "Content-Type": "application/json",
          },
          data: planData,
        };

        axios(config)
          .then(function (response) {
            console.log(planData, response, "planData");
            console.log(JSON.stringify(response.data.meta.code));
            setLoader(false);
            if (response.data.meta.code === "200") {
              toast.success(<h6>Plan Added successfully</h6>);
            }
            //
          })
          .catch(function (error) {
            console.log(error);
            setLoader(false);

            toast.success(<h6>Something wrong happen</h6>);
          });
      } else {
        setLoader(true);
        var planData = JSON.stringify({
          id: data && data.id,
          plan_name: values.planName,
          monthly_price_per_license: values.price,
          plan_price_per_license: values.pricePerLicense,
          plan_min_licenses: values.minLicense,
          plan_max_licenses: values.maxLicense,
          plan_call_max_participants: values.callParticipant,
          plan_call_max_minutes: values.callMaxMinutes,
        });

        var config = {
          method: "post",
          url: "admin/plans/edit_plan",
          headers: {
            token: token,
            Accept: "application/json",
            Authorization: "Bearer asd",
            "Content-Type": "application/json",
          },
          data: planData,
        };

        axios(config)
          .then(function (response) {
            setLoader(false);
            console.log(response, data, "updateddata");
            if (response.data.meta.code === "200") {
              toast.success(<h6>Plan updated successfully</h6>);
            }
            //
          })
          .catch(function (error) {
            console.log(error);
            setLoader(false);

            toast.success(<h6>Something wrong happen</h6>);
          });
      }
    },
  });

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilteredComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Add Plan
                </MDTypography>
              </MDBox>
              {!loader ? (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <MDBox pt={3} xs={8} component="form" autoComplete="off">
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "75%",
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <TextField
                            required
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Plan Name"
                            name="planName"
                            type="text"
                            fullWidth
                            variant="standard"
                            className="Meeting_heading-full"
                            defaultValue={
                              data && data.name
                                ? data.name
                                : formik.values.planName
                            }
                            onChange={formik.handleChange}
                            error={
                              formik.touched.planName &&
                              Boolean(formik.errors.planName)
                            }
                            helperText={
                              formik.touched.planName && formik.errors.planName
                            }
                          />
                        </div>

                        <Box
                          sx={{
                            width: "100%",

                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              width: "48%",
                            }}
                          >
                            <div>
                              <TextField
                                required
                                margin="dense"
                                id="name"
                                label="Monthly price per license"
                                type="text"
                                fullWidth
                                variant="standard"
                                className="Meeting_heading-"
                                name="price"
                                defaultValue={
                                  data && data.monthly_price_per_license
                                    ? data.monthly_price_per_license
                                    : formik.values.price
                                }
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.price &&
                                  Boolean(formik.errors.price)
                                }
                                helperText={
                                  formik.touched.price && formik.errors.price
                                }
                              />
                            </div>
                          </Box>
                          <Box
                            sx={{
                              width: "48%",
                            }}
                          >
                            <div>
                              <TextField
                                required
                                margin="dense"
                                id="name"
                                label="Yearly price per license"
                                type="text"
                                fullWidth
                                variant="standard"
                                className="Meeting_heading-"
                                name="pricePerLicense"
                                defaultValue={
                                  data && data.price_per_license
                                    ? data.price_per_license
                                    : formik.values.pricePerLicense
                                }
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.pricePerLicense &&
                                  Boolean(formik.errors.pricePerLicense)
                                }
                                helperText={
                                  formik.touched.pricePerLicense &&
                                  formik.errors.pricePerLicense
                                }
                              />
                            </div>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            width: "100%",

                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              width: "48%",
                            }}
                          >
                            <div>
                              <TextField
                                required
                                margin="dense"
                                id="name"
                                label="Min Licesnce"
                                type="text"
                                fullWidth
                                variant="standard"
                                className="Meeting_heading-"
                                defaultValue={
                                  data && data.min_licenses
                                    ? data.min_licenses
                                    : formik.values.minLicense
                                }
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.minLicense &&
                                  Boolean(formik.errors.minLicense)
                                }
                                helperText={
                                  formik.touched.minLicense &&
                                  formik.errors.minLicense
                                }
                                name="minLicense"
                              />
                            </div>
                          </Box>
                          <Box
                            sx={{
                              width: "48%",
                            }}
                          >
                            <div>
                              <TextField
                                required
                                margin="dense"
                                id="name"
                                label="Max Licesnce"
                                type="text"
                                fullWidth
                                variant="standard"
                                className="Meeting_heading-"
                                defaultValue={
                                  data && data.max_licenses
                                    ? data.max_licenses
                                    : formik.values.maxLicense
                                }
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.maxLicense &&
                                  Boolean(formik.errors.maxLicense)
                                }
                                helperText={
                                  formik.touched.maxLicense &&
                                  formik.errors.maxLicense
                                }
                                name="maxLicense"
                              />
                            </div>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            width: "100%",

                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              width: "48%",
                            }}
                          >
                            <div>
                              <TextField
                                required
                                margin="dense"
                                id="name"
                                label="Call Participants"
                                type="text"
                                fullWidth
                                variant="standard"
                                className="Meeting_heading-"
                                defaultValue={
                                  data && data.call_max_participants
                                    ? data.call_max_participants
                                    : formik.values.callParticipant
                                }
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.callParticipant &&
                                  Boolean(formik.errors.callParticipant)
                                }
                                helperText={
                                  formik.touched.callParticipant &&
                                  formik.errors.callParticipant
                                }
                                name="callParticipant"
                              />
                            </div>
                          </Box>
                          <Box
                            sx={{
                              width: "48%",
                            }}
                          >
                            <div>
                              <TextField
                                required
                                margin="dense"
                                id="name"
                                label="Call Max Minutes"
                                type="text"
                                fullWidth
                                variant="standard"
                                className="Meeting_heading-"
                                defaultValue={
                                  data && data.call_max_minutes
                                    ? data.call_max_minutes
                                    : formik.values.callMaxMinutes
                                }
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.callMaxMinutes &&
                                  Boolean(formik.errors.callMaxMinutes)
                                }
                                helperText={
                                  formik.touched.callMaxMinutes &&
                                  formik.errors.callMaxMinutes
                                }
                                name="callMaxMinutes"
                              />
                            </div>
                          </Box>
                        </Box>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <Box
                            sx={{
                              width: "48%",
                            }}
                          >
                            <div className="plan-category-btn-">
                              <button
                                type="submit"
                                onClick={formik.handleSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </Box>
                          {/* onClick={handlePlanSubmit} */}
                        </div>
                      </Box>
                    </Box>
                  </MDBox>
                </form>
              ) : (
                // </Formik>
                <FullPageLoader />
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default PlanPopUp;
