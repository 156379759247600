import React, { useState, useMemo, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FilteredComponent from "components/FilteredComponent";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { FcNext } from "react-icons/fc";
import { useParams } from "react-router-dom";
import { UserViewBillingData } from "components/Samples";
import { FiFilter } from "react-icons/fi";
import Footer from "examples/Footer";
import Swal from "sweetalert2/dist/sweetalert2.js";
import DataTable from "react-data-table-component";
import { useTable, usePagination } from "react-table";
import UpgraderPlan from "components/popup/UpgradePlan";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import { FcPrevious } from "react-icons/fc";
import Moment from "moment";

function UserDetail() {
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [controller, dispatch] = useMaterialUIController();
  const { userAuth } = controller;
  const token = userAuth.login_token;
  const [count, setCount] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [FilterName, setFilterName] = useState("");
  const [FilterEmail, setFilterEmail] = useState("");
  const [pageNumber, setpageNumber] = useState(0);
  const [start_date, set_start_date] = useState(
    Moment().subtract(2, "month").format("YYYY-MM-DD")
  );
  const [end_date, set_end_date] = useState(
    Moment().add(2, "day").format("YYYY-MM-DD")
  );

  const { id } = useParams();

  console.log(id, "responseresponse");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [open, setOpen] = useState(false);

  const handleDateEndChange = (e) => {
    set_end_date(e.target.value);
  };

  const handleDateChange = (e) => {
    set_start_date(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Pagination",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
  };
  const columns = [
    {
      name: "Plan Name",
      selector: (row) => row?.subscription?.plan?.name,
    },
    {
      name: "Payment method",
      selector: (row) => row?.subscription?.payment_method,
    },
    {
      name: "Start date",
      selector: (row) =>
        row?.subscription
          ? Moment(row?.subscription?.start_date).format("YYYY-MM-DD")
          : "N/A",
    },
    {
      name: "Expiry date",
      selector: (row) =>
        row?.subscription
          ? Moment(row?.subscription?.expiry_date).format("YYYY-MM-DD")
          : "N/A",
    },
    {
      name: "Updated at",

      selector: (row) =>
        row?.subscription
          ? Moment(row?.subscription?.updated_at).format("YYYY-MM-DD")
          : "N/A",
    },
    {
      name: "Amount",
      selector: (row) => (row?.amount ?  `$ ${row?.amount}` : "N/A"),
    },
    {
      name: "Call max participants",
      selector: (row) => row?.subscription?.call_max_participants,
    },
  ];

  const getTableData = () => {
    const userdata = {
      user_id: id,
    };
    const actionResp = UserViewBillingData(token, userdata);

    actionResp
      .then((resp) => {
        const data = resp.data.data;
        console.log(data, "datadata");
        setTableData(data);
        if (resp.status) {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        return error;
      });
  };

  useEffect(() => {
    getTableData();
  }, [id]); // Call getTableData whenever pageNumber changes

  const ResultfilteredItems = tableData?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  let filteredItems = ResultfilteredItems?.filter(
    (item) => item?.is_admin !== 1
  );
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilteredComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const nextPage = () => {
    setpageNumber(pageNumber + 1);
  };

  const prevPage = () => {
    if (pageNumber > 0) {
      setpageNumber(pageNumber - 1);
    }
  };

  return (
    <DashboardLayout>

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Billing Detail
                </MDTypography>
               
              </MDBox>
              {loader ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <MDBox pt={3}>
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                    //   subHeader
                    //   subHeaderComponent={subHeaderComponent}
                    />
                  </MDBox>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default UserDetail;
