import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { blue, blueGrey } from '@material-ui/core/colors';

// const PrimaryButton = withStyles(() => ({
//   root: {
//     color: '#fff',
//     backgroundColor: '#0cb36d',
//   },
// }))(Button)

const PrimaryButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
}))(Button);


export default PrimaryButton
