import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import "./pricing.css";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { Container, Row, Col } from "react-bootstrap";
function ProfileInfoCard({ title, description, info, social, action, shadow }) {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Container>
      <Row className="form-wrapper">
        <Col
          sm={12}
          className="d-flex justify-content-between col-main-wrapper"
        >
          <Col sm={5}>
            {" "}
            <div className="form-heading">
              <h4>Choose Your Plan :</h4>
            </div>
          </Col>
          <Col sm={7} className="d-flex justify-content-end">
            <div className="form-select- col-sm-12">
              <select>
                <option></option>
                <option value="grapefruit">Pro</option>
                <option value="lime">Small Business</option>
                <option value="coconut">Enterprise</option>
              </select>
            </div>
          </Col>
        </Col>
        <Col sm={12} className="d-flex justify-content-between">
          <Col sm={5}>
            {" "}
            <div className="form-heading">
              <h4>Choose Your Plan Type :</h4>
            </div>
          </Col>
          <Col sm={7} className="d-flex justify-content-end">
            <div className="form-select- col-sm-12">
              <select>
                <option></option>
                <option value="grapefruit">Monthly</option>
                <option value="lime">Yearly</option>
              </select>
            </div>
          </Col>
        </Col>
        <Col sm={12} className="d-flex justify-content-between">
          <Col sm={5}>
            {" "}
            <div className="form-heading">
              <h4>Please Enter Price Per License</h4>
            </div>
          </Col>
          <Col sm={7} className="d-flex justify-content-end">
            <div className="form-select- col-12">
              <input type="text" placeholder="Price Per License" />
            </div>
          </Col>
        </Col>
        <Col sm={12} className="d-flex justify-content-between">
          <Col sm={5}>
            {" "}
            <div className="form-heading">
              <h4>Please Enter Discount</h4>
            </div>
          </Col>
          <Col sm={7} className="d-flex justify-content-end">
            <div className="form-select- col-12">
              <input type="text" placeholder="Price Per License" />
            </div>
          </Col>
        </Col>
        <Col sm={12} className="d-flex justify-content-between">
          <Col sm={5}>
            {" "}
            <div className="form-heading">
              <h4>Total Number of Licenses are</h4>
            </div>
          </Col>
          <Col sm={7} className="d-flex justify-content-start">
            130
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default ProfileInfoCard;
