import { useState, useMemo, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FilteredComponent from "components/FilteredComponent";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  useMaterialUIController,
  setMiniSidenav,
  setUserLoginRequest,
  setWhiteSidenav,
} from "context";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { UserTableData, UserStatus } from "components/Samples";
import Footer from "examples/Footer";
import Swal from "sweetalert2/dist/sweetalert2.js";
import DataTable from "react-data-table-component";
import { useTable, usePagination } from "react-table";
import UpgraderPlan from "components/popup/UpgradePlan";
import { Link } from "react-router-dom";
function Enterprise() {
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [controller, dispatch] = useMaterialUIController();
  const { userAuth } = controller;
  const token = userAuth.login_token;
  const [count, setCount] = useState(0);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const auth = JSON.parse(localStorage.getItem('user'));
  const admin_type = auth?.admin

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpgrade = (e, data) => {
    setUserData(data);
    setOpen(true);
    console.log(data, "userData");
  };
  const handleButtonClick = (e, id) => {
    console.log(id, "checkid");
    const userId = id?.user_id;

    console.log(userId, "userId");

    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete all the data regarding this user (Meetings, Messages, Contacts, etc).",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        const data = JSON.stringify({
          user_id: userId,
        });

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "admin/user/delete_user",
          headers: {
            token: token,
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer asd",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            console.log(response, data, "responseresponse");
            Swal.fire("Deleted!", "User has been deleted.", "success");
            setCount(count + 1);
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error!", "Failed to delete user.", "error");
          });
      }
    });
  };
  const paginationComponentOptions = {
    rowsPerPageText: "Pagination",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
  };
  const columns = [
    {
      name: "Profile Pic",
      selector: (row) => (
        <img
          className="table-image"
          src={row.user.profile_picture_url}
          alt="image description"
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      name: "Name",
      selector: (row) => {
        const MAX_NAME_LENGTH = 20;
        return row.user.first_name?.length > MAX_NAME_LENGTH
          ? row.user.first_name?.substring(0, MAX_NAME_LENGTH) + "..."
          : row.user.first_name;
      },
    },
    {
      name: "Email",
      selector: (row) => row.user.email,
    },
    {
      name: "Status",
      selector: (row) => row.user.status,
    },
    admin_type==1 && {
      name: "Plan",
      cell: (data) => (
        <button
          onClick={(e) => handleUpgrade(e, data)}
          className={"active-btn"}
        >
          upgrade
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    admin_type==1 && {
      name: "Action",
      cell: (data) => (
        <button
          onClick={(e) => handleButtonClick(e, data)}
          className={"active-btn"}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Action",
      cell: (data) => (
        <Link to="/view-profile" state={data}>
          {" "}
          <button className={"active-btn"}>View</button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

    admin_type==1 && {
      name: "Action",
      cell: (data) => (
        <Link to="/add-new-user" state={data}>
          {" "}
          <button className={"active-btn"}>Edit</button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    // {
    //   name: "Action",
    //   cell: (data) => (
    //     <button
    //       onClick={(e) => handleButtonClick(e, data)}
    //       className={data.user.status == "active" ? "active-btn" : "block-btn"}
    //     >
    //       {data.user.status == "active" ? "Active" : "Block"}
    //     </button>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ];

  const filtercolumns = [
    {
      name: "Profile Pic",
      selector: (row) => (
        <img
          className="table-image"
          src={row.user.profile_picture_url}
          alt="image description"
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      name: "Name",
      selector: (row) => {
        const MAX_NAME_LENGTH = 20;
        return row.user.first_name?.length > MAX_NAME_LENGTH
          ? row.user.first_name?.substring(0, MAX_NAME_LENGTH) + "..."
          : row.user.first_name;
      },
    },
    {
      name: "Email",
      selector: (row) => row.user.email,
    },
    {
      name: "Status",
      selector: (row) => row.user.status,
    },

    {
      name: "Action",
      cell: (data) => (
        <Link to="/view-profile" state={data}>
          {" "}
          <button className={"active-btn"}>View</button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },


  ];

  const getTableData = () => {
    const actionResp = UserTableData(token, 4);

    actionResp
      .then((resp) => {
        const data = resp.data.data.data;
        setTableData(data);
        console.log(data);
        if (resp.status) {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        return error;
      });
  };

  useEffect(() => {
    getTableData();
  }, [count]);
  const ResultfilteredItems = tableData?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  let filteredItems = ResultfilteredItems.filter(
    (item) => item.user?.is_admin !== 1
  );
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilteredComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Business Pro Members Data
                </MDTypography>
              </MDBox>
              {loader ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <MDBox pt={3}>
                  <DataTable
                     columns={admin_type == 1 ?  columns: filtercolumns}
                    data={filteredItems}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    subHeader
                    subHeaderComponent={subHeaderComponent}
                    // noDataComponent="oops nothing to show here"
                  />
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <UpgraderPlan
        open={open}
        handleClose={handleClose}
        userData={userData}
        getTableData={getTableData}
      />
    </DashboardLayout>
  );
}

export default Enterprise;
