import { useState, useMemo, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import "./AddNewUser.css";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FilteredComponent from "components/FilteredComponent";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import { useMaterialUIController } from "context";
import moment from "moment";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import FullPageLoader from "components/FullPageLoader/FullPageLoader";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
const phoneRegExp = /^\d{10}$/;
function AddNewUser({ edit }) {
  const location = useLocation();
  const data = location?.state;
  console.log(data, "data");
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { userAuth } = controller;
  const token = userAuth.login_token;
  const [count, setCount] = useState(0);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("us");
  const [minLicense, setminLicense] = useState("");
  const [initailstate, setInitialState] = useState(true);
  const phoneRef = useRef(null);
  const [changeColor, setChangeColor] = useState({
    phoneNumber: false,
  });
  const [phoneNumberInvalid, setPhoneNumberInvalid] = useState(false);

  console.log(selectedCountry, "selectedCountry");
  //   useEffect(() => {
  //     const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //     const countryCode = moment.tz(timeZone).zoneAbbr();
  //     const firstTwoLowercase = countryCode.slice(0, 2).toLowerCase();
  //     setSelectedCountry(firstTwoLowercase)
  //     setInitialState(true)

  //   }, []);

  const initialProfileState = {
    country_code: "",
    phone: "",
  };

  const [currentProfile, setInitialProfileState] =
    useState(initialProfileState);

  // useEffect(() => {
  //   const countries = phoneRef.current?.countries;
  //   const country = countries?.find(
  //     (c) => c.dialCode == data?.user?.country_code?.replace("+", "")
  //   );
  //   if (country && data?.user?.phone) {
  //     setSelectedCountry(country.iso2);
  //   } else {
  //     setSelectedCountry("us");
  //   }
  //   setInitialProfileState({
  //     country_code: data?.user?.country_code,
  //     phone: data?.user?.phone,
  //   });
  // }, [data]);


  const handleFlagSelect = (e) => {
    if (phoneRef.current?.selectedCountryData) {
      setPhoneNumberInvalid(false);
      setInitialProfileState({
        ...currentProfile,
        country_code: "+" + phoneRef.current.selectedCountryData.dialCode,
        phone: "",
      });
    }
  };

  const handlePhoneFocus = (e) => {
    setChangeColor((prev) => ({
      ...prev,
      phoneNumber: true,
    }));
  };

  const resetColorState = () => {
    setChangeColor({
      phoneNumber: false,
    });
  };

  const validatePhoneNumber = (isValid, newNumber, countryData) => {
    if (!newNumber) {
      setPhoneNumberInvalid(false);
    } else if (isNaN(newNumber) || !isValid) setPhoneNumberInvalid(true);
    else setPhoneNumberInvalid(false);
    setInitialProfileState({
      ...currentProfile,
      phone: newNumber,
      country_code: "+" + countryData?.dialCode,
    });
  };

  var a = !data ? false : true;
  const adduser = a
    ? Yup.object().shape({
      fname: Yup.string()
        .matches(/^[A-Za-z ]+$/, "First Name must contain only letters")
        .min(2, "First Name Too Short!")
        .max(50, "First Name Too Long!")
        .required("First Name is Required"),
      lname: Yup.string()
        .matches(/^[A-Za-z ]+$/, "Last Name must contain only letters")
        .typeError("Last Name must be a string!")
        .min(2, "Last Name Too Short!")
        .max(50, "Last Name Too Long!")
        .required("Last Name is Required"),
      Username: Yup.string()
        .min(2, "Username Too Short!")
        .max(50, "Username Too Long!"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
    })
    : Yup.object().shape({
      fname: Yup.string()
        .matches(/^[A-Za-z ]+$/, "First Name must contain only letters")
        .min(2, "First Name Too Short!")
        .max(50, "First Name Too Long!")
        .required("First Name is Required"),
      lname: Yup.string()
        .matches(/^[A-Za-z ]+$/, "Last Name must contain only letters")
        .typeError("Last Name must be a string!")
        .min(2, "Last Name Too Short!")
        .max(50, "Last Name Too Long!")
        .required("Last Name is Required"),
      Username: Yup.string()
        .min(2, "Username Too Short!")
        .max(50, "Username Too Long!"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .max(50, "Password is too long")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        )
        .required("Password is required"),
    });

  console.log(data, "datata");
  const formik = useFormik({
    initialValues: {
      fname: data && data?.user?.first_name ? data?.user?.first_name : "",
      lname: data && data?.user?.last_name ? data?.user?.last_name : "",
      Username:
        data && data?.user?.display_name ? data?.user?.display_name : "",
      email: data && data?.user?.email ? data?.user?.email : "",
      password: "",
    },
    validationSchema: adduser,

    onSubmit: (values) => {
      console.log("phone number");
      if (a === false) {
        if (phoneNumberInvalid) {
          toast.error("Please enter a valid phone number");
          return;
        } else {
          setLoader(true);
          if (!currentProfile?.phone) {
            var UserData = JSON.stringify({
              first_name: values.fname,
              last_name: values.lname,
              display_name: values.Username,
              email: values.email,
              password: values.password,
              country_code: "",
              phone: "",
            });
          } else {
            var UserData = JSON.stringify({
              first_name: values.fname,
              last_name: values.lname,
              display_name: values.Username,
              email: values.email,
              password: values.password,
              country_code: currentProfile?.country_code,
              phone: currentProfile?.phone,
            });
          }

          var config = {
            method: "post",
            url: "admin/user/add_user",
            headers: {
              token: token,
              Accept: "application/json",
              Authorization: "Bearer asd",
              "Content-Type": "application/json",
            },
            data: UserData,
          };

          axios(config)
            .then(function (response) {
              setLoader(false);
              if (response.data.meta.code === "200") {
                toast.success(<h6>User Added successfully</h6>);
                navigate("/tables/basic-members");
                setInitialProfileState({
                  country_code: "+1",
                  phone: "",
                });
                setPhoneNumberInvalid(false);
              } else {
                toast.error(<h6>{response.data.meta.message}</h6>);
                setInitialProfileState({
                  country_code: "+1",
                  phone: "",
                });
                setPhoneNumberInvalid(false);
              }
            })
            .catch(function (error) {
              console.log(error);
              setLoader(false);
              toast.success(<h6>Something Went Wrong</h6>);
              setInitialProfileState({
                country_code: "+1",
                phone: "",
              });
              setPhoneNumberInvalid(false);
            });
        }
      } else {
        console.log("phone number");
        setLoader(true);

        var UserData = JSON.stringify({
          user_id: data?.user_id,
          first_name: values.fname,
          last_name: values.lname,
          display_name: values.Username,
          // email: values.email,
          // password: values.password,
          country_code: data?.user?.country_code,
          phone: data?.user?.phone,
        });

        var config = {
          method: "post",
          url: "admin/user/edit_user",
          headers: {
            token: token,
            Accept: "application/json",
            Authorization: "Bearer asd",
            "Content-Type": "application/json",
          },
          data: UserData,
        };

        axios(config)
          .then(function (response) {
            console.log(response, UserData, "responseresponse");
            setLoader(false);
            if (response.data.meta.code === "200") {
              toast.success(<h6>User Updated successfully</h6>);
              // navigate("/tables/basic-members");
              setInitialProfileState({
                country_code: "+1",
                phone: "",
              });
              setPhoneNumberInvalid(false);
            } else {
              toast.error(<h6>{response.data.meta.message}</h6>);
              setInitialProfileState({
                country_code: "+1",
                phone: "",
              });
              setPhoneNumberInvalid(false);
            }
          })
          .catch(function (error) {
            console.log(error);
            console.log(error, "responseresponse");
            setLoader(false);
            toast.success(<h6>Something Went Wrong</h6>);
            setInitialProfileState({
              country_code: "+1",
              phone: "",
            });
            setPhoneNumberInvalid(false);
          });
      }
    },
  });
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilteredComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Add New User
                </MDTypography>
              </MDBox>
              {!loader ? (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <MDBox pt={3} xs={8} component="form" autoComplete="off">
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "75%",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",

                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              width: "48%",
                            }}
                          >
                            <div>
                              <TextField
                                required
                                margin="dense"
                                id="name"
                                label="First Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                className="Meeting_heading-"
                                defaultValue={
                                  data && data?.user?.first_name
                                    ? data?.user?.first_name
                                    : ""
                                }
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.fname &&
                                  Boolean(formik.errors.fname)
                                }
                                helperText={
                                  formik.touched.fname && formik.errors.fname
                                }
                                name="fname"
                              />
                            </div>
                          </Box>
                          <Box
                            sx={{
                              width: "48%",
                            }}
                          >
                            <div>
                              <TextField
                                required
                                margin="dense"
                                id="name"
                                label="Last Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                className="Meeting_heading-"
                                defaultValue={
                                  data && data?.user?.last_name
                                    ? data?.user?.last_name
                                    : ""
                                }
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.lname &&
                                  Boolean(formik.errors.lname)
                                }
                                helperText={
                                  formik.touched.lname && formik.errors.lname
                                }
                                name="lname"
                              />
                            </div>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            width: "100%",

                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              width: "48%",
                            }}
                          >
                            <div>
                              <TextField
                                required
                                margin="dense"
                                id="name"
                                label="Display name"
                                type="text"
                                fullWidth
                                variant="standard"
                                className="Meeting_heading-"
                                name="Username"
                                defaultValue={
                                  data && data?.user?.display_name
                                    ? data?.user?.display_name
                                    : ""
                                }
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.Username &&
                                  Boolean(formik.errors.Username)
                                }
                                helperText={
                                  formik.touched.Username &&
                                  formik.errors.Username
                                }
                              />
                            </div>
                          </Box>
                          <Box
                            sx={{
                              width: "48%",
                            }}
                          >
                            <div>
                              <TextField
                                required
                                margin="dense"
                                id="name"
                                label="Email"
                                type="text"
                                fullWidth
                                variant="standard"
                                className="Meeting_heading-"
                                name="email"
                                defaultValue={
                                  data && data?.user?.email
                                    ? data?.user?.email
                                    : ""
                                }
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.email &&
                                  Boolean(formik.errors.email)
                                }
                                helperText={
                                  formik.touched.email && formik.errors.email
                                }
                              />
                            </div>
                          </Box>
                        </Box>

                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          {!a && initailstate && (
                            <IntlTelInput
                              defaultCountry={selectedCountry}
                              onSelectFlag={handleFlagSelect}
                              separateDialCode={true}
                              ref={phoneRef}
                              containerClassName="intl-tel-input"
                              placeholder={"Enter your mobile number"}
                              name="phone"
                              onPhoneNumberFocus={handlePhoneFocus}
                              onPhoneNumberBlur={resetColorState}
                              value={
                                !currentProfile.phone
                                  ? ""
                                  : currentProfile.phone
                              }
                              onPhoneNumberChange={(...args) =>
                                validatePhoneNumber(...args)
                              }
                              inputClassName="form-control intl-tel-input-phone"
                            />
                          )}
                        </div>

                        {!a && phoneNumberInvalid && (
                          <div className="profileInputError">
                            Mobile Number is Invalid
                          </div>
                        )}

                        {!a && (
                          <Box
                            sx={{
                              width: "100%",

                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                              }}
                            >
                              <div>
                                <TextField
                                  required
                                  margin="dense"
                                  id="name"
                                  label="Password"
                                  type="text"
                                  fullWidth
                                  variant="standard"
                                  className="Meeting_heading-"
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.password &&
                                    Boolean(formik.errors.password)
                                  }
                                  helperText={
                                    formik.touched.password &&
                                    formik.errors.password
                                  }
                                  name="password"
                                />
                              </div>
                            </Box>
                          </Box>
                        )}

                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <Box
                            sx={{
                              width: "48%",
                            }}
                          >
                            <div className="plan-category-btn-">
                              <button
                                type="submit"
                                onClick={formik.handleSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </Box>
                          {/* onClick={handlePlanSubmit} */}
                        </div>
                      </Box>
                    </Box>
                  </MDBox>
                </form>
              ) : (
                // </Formik>
                <FullPageLoader />
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddNewUser;
