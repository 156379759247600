export function getUrlParameter(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export function getCurrentUser() {
  var user = localStorage.getItem("user");
  return user ? JSON.parse(user) : false;
}

export function convertToJson(data, escapse_unicode = false) {
  data = JSON.stringify(data);
  if (escapse_unicode) {
    data = data.replace(/[\u007F-\uFFFF]/g, function (chr) {
      return "\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
    });
  }
  return data;
}

export function isJson(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
