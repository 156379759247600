import { useState, useMemo, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FilteredComponent from "components/FilteredComponent";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
    useMaterialUIController,
} from "context";

// Material Dashboard 2 React example components

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { UserTableData, UserStatus, PlanCategoryData } from "components/Samples";
import Footer from "examples/Footer";
import Swal from "sweetalert2/dist/sweetalert2.js";
import DataTable from "react-data-table-component";
import { useTable, usePagination } from "react-table";
import FormDialog from "../../components/popup/FormDialog"
function Enterprise() {
    const [tableData, setTableData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [controller, dispatch] = useMaterialUIController();
    const { userAuth } = controller;
    const token = userAuth.login_token;
    const [count, setCount] = useState(0);
    const [filterText, setFilterText] = useState("");
    const [updateValues, setUpdateValues] = useState()
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [edit, setEdit] = useState(false)

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setUpdateValues("")
        setEdit(false)
    };
    const handleButtonClick = (e, data) => {

        setUpdateValues(data)
        setOpen(true)
        setEdit(true)
    };
    const paginationComponentOptions = {
        rowsPerPageText: "Pagination",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
    };
    const columns = [
        {
            name: "Name",
            selector: (row) => row.name,
        },
        {
            name: "Status",
            selector: (row) => row.status,
        },
        // {
        //     name: "Action",
        //     cell: (data) => (
        //         <button
        //             onClick={(e) => handleButtonClick(e, data)}
        //             className={data.status == "active" ? "active-btn" : "block-btn"}
        //         >
        //             {data.status == "active" ? "Edit" : "Edit"}
        //         </button>
        //     ),
        //     ignoreRowClick: true,
        //     allowOverflow: true,
        //     button: true,
        // },
    ];

    useEffect(() => {
        const actionResp = PlanCategoryData(token)

        actionResp
            .then((resp) => {
                const data = resp.data.data;
                const filterData = data.filter( da => da.status != "disable" && da.name != "")
                console.log(filterData , "filterData")
                setTableData(filterData);
                if (resp.status) {
                    setLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoader(false);
                return error;
            });
    }, [count]);
    const ResultfilteredItems = tableData?.filter(
        (item) =>
            JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
            -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilteredComponent
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <><DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <MDTypography variant="h6" color="white">
                                    Plan Categories
                                </MDTypography>
                                {/* <button variant="h6" color="white" className="add-btn" onClick={handleClickOpen}>
                                    Add
                                </button> */}
                            </MDBox>
                            {loader ? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "50vh",
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <MDBox pt={3}>
                                    <DataTable
                                        columns={columns}
                                        data={ResultfilteredItems}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                        subHeader
                                        subHeaderComponent={subHeaderComponent}
                                    // noDataComponent="oops nothing to show here"
                                    />
                                </MDBox>
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
            <FormDialog open={open} handleClose={handleClose} Values={updateValues} edit={edit} />
        </>
    );
}

export default Enterprise;
