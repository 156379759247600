import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { NavLink } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import {
  useMaterialUIController,
  setMiniSidenav,
  setUserLoginRequest,
  setWhiteSidenav,
} from "context";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import { UserCount } from "components/Samples";
// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
//React-icons used in Dashboard
import { FaUserAstronaut, FaUserSecret, FaUsers } from "react-icons/fa";
import { ImUsers } from "react-icons/im";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const [controller, dispatch] = useMaterialUIController();
  const [count, setCount] = useState();
  const { userAuth } = controller;
  const token = userAuth.login_token;
  useEffect(() => {
    const userCountResp = UserCount(token);
    userCountResp
      .then((resp) => {
        console.log(resp.data.data);
        setCount(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <NavLink to="/tables/basic-members">
                <ComplexStatisticsCard
                  icon={<FaUsers />}
                  title="Basic Users"
                  count={(count && count[0].member_count) || 0}
                />
              </NavLink>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <NavLink to="/tables/pro-members">
                <ComplexStatisticsCard
                  color="success"
                  icon={<FaUserSecret />}
                  title="Pro Users"
                  count={(count && count[1].member_count) || 0}
                />
              </NavLink>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <NavLink to="/tables/business-members">
                <ComplexStatisticsCard
                  color="primary"
                  icon={<FaUserAstronaut />}
                  title="Business Users"
                  count={(count && count[2].member_count) || 0}
                />
              </NavLink>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <NavLink to="/tables/Enterprise-members">
                <ComplexStatisticsCard
                  color="error"
                  icon={<ImUsers />}
                  title="Business Pro Users"
                  count={(count && count[3].member_count) || 0}
                />
              </NavLink>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
